import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import { FaPhone, FaMapMarkerAlt, FaEnvelope, FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
const Footer = () => {
    const getImg = graphql`
	{
	abstrahere:file(relativePath: {eq: "abstrahere.png"}) {
		childImageSharp {
		fluid(maxWidth: 250) {
			...GatsbyImageSharpFluid
		}
		}
	}
	}`

    const { abstrahere } = useStaticQuery(getImg);
    return (
        <>
            <footer className="piegeneral">
                <Container>
                    <Row>
                        <Col lg={3}>
                            <div style={{ maxWidth: 250, marginBottom: 50 }}>
                                <Image fluid={abstrahere.childImageSharp.fluid} />
                            </div>
                        </Col>

                        <Col lg={5} md={6} sm={12}>
                            {/* <div className="titupie">Encuéntranos</div> */}
                            <div className="mb-2">
                                <div className="subtitupie"><FaMapMarkerAlt /> Encuéntranos</div>
                                <div>Barros Arana 162, Oficina 81, Concepción </div>
                            </div>
                            <div className="mb-2">
                                <div className="subtitupie"><FaPhone /> Llámanos </div>
                                <div>Comercial : (+56 41) 2560468 </div>
                                <div>Soporte : (+56 2) 32103189 </div>
                            </div>
                            <div>
                                <div className="subtitupie"><FaEnvelope /> Escríbenos </div>
                                <div>contacto@abstrahere.com</div>
                            </div>

                        </Col>
                        <div className="separacion-solo-s" />
                        <Col lg={4} md={6} sm={12}>
                            Abstrahere empresa especialista en documentación electrónica. Posee soluciones para la generación, administración, recepción y cesión de facturas electrónicas para pequeñas, medianas y grandes empresas.
                            <div className="mt-3">
                                <a className="rrss text-white" href="https://www.facebook.com/abstraherechile/" target="_blank" rel="noreferrer"><FaFacebookF /><span >.</span></a>
                                <a className="rrss text-white" href="https://twitter.com/abstraherechile" target="_blank" rel="noreferrer"><FaTwitter /><span >.</span></a>
                                <a className="rrss text-white" href="https://www.linkedin.com/company/abstrahere-s-p-a" target="_blank" rel="noreferrer"><FaLinkedinIn /><span >.</span></a>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="pieproductos">
                    <div className="container">
                        <div className="row nmb">
                            <div className="linkspieproductos">
                                <span><a href="http://www.facturae.cl" target="_blank" rel="noopener noreferrer" className="linkprod">Facturación electrónica</a></span> |
                            <span><a href="http://www.cesione.cl" target="_blank" rel="noopener noreferrer" className="linkprod">Cesión electrónica</a></span> |
                            <span><a href="http://www.recepcione.cl" target="_blank" rel="noopener noreferrer" className="linkprod">Recepción electrónica</a></span> |
                            <span><a href="http://www.dtecloud.cl" target="_blank" rel="noopener noreferrer" className="linkprod">Facturación integrada</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer
