import React, { useState, useEffect } from "react"
import Footer from './Footer';
import PropTypes from "prop-types"
import Header from "./header"
import "./layout.scss"

const getScrollNode = (element) => {
	return element.ownerDocument.scrollingElement || element.ownerDocument.documentElement
}

const isScrolled = (element) => {
	const scrollNode = getScrollNode(element)
	return scrollNode.scrollTop > 0
}

const Layout = (props) => {
	const { className: classProps, children } = props;
	const siteContainer = React.createRef()

	const [scrolled, setScrolled] = useState(false)
	const [className, setClassName] = useState('')


	useEffect(() => {

		const element = siteContainer.current

		const handleScroll = () => {
			setScrolled(isScrolled(element))
		}
		setClassName("site-container")

		if (classProps) {
			setClassName(className + ` ${classProps}`)
		}

		window.addEventListener("scroll", handleScroll)
		setScrolled(isScrolled(element))
		/* 	return function cleanup() {
				window.removeEventListener("scroll", handleScroll)
			}; */
	}, [className, classProps, siteContainer])

	useEffect(() => {

		if (scrolled) {
			setClassName("site-container navbar-scrolled")
		}
		if (classProps) {
			setClassName(className + ` ${classProps}`)
		}

	}, [scrolled, className, setClassName, classProps])


	return (
		<div
			className={"site-container navbar-scrolled"}
			ref={siteContainer}
			id="page-top">
			<Header />
			<main>{children}</main>
			<Footer />
		</div>
	)

}

export default Layout;

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
}