import React from "react"
import Scrollspy from "react-scrollspy"
import { Navbar, Nav, Container } from "react-bootstrap"
import Scroller from './scroller'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import { FaUserAlt, FaUnlockAlt } from "react-icons/fa";
const Header = () => {
  const getImagenes = graphql`
  {
    marca:file(relativePath: {eq: "marca.png"}) {
      childImageSharp {
        fluid(maxWidth: 220) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    sii:file(relativePath: {eq: "reconocida_sii2.png"}) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    poweredby:file(relativePath: {eq: "poweredby.png"}) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
  }`

  const data = useStaticQuery(getImagenes);
  const { marca, sii, poweredby } = data;

  return (
    <>
      <Navbar className="navbar navbar-expand-lg navbar-light fixed-top py-2" id="mainNav" expand="md"
        collapseOnSelect={true}>
        <Container>
          <button onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }} className="marca">
            <Image fluid={marca.childImageSharp.fluid} />
          </button>
          <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse id="navbarResponsive">
            <Nav className="navbar-nav ml-auto my-2 my-lg-0">
              <Scrollspy className="navbar-nav"
                items={["sobre", "contact"]}
                currentClassName="active" rootEl={"#mainNav"}>
                <li className="nav-item">
                  <Nav.Link className={"js-scroll-trigger"} href="#inicio" onClick={Scroller.handleAnchorScroll}>Inicio</Nav.Link>
                </li>
                <li className="nav-item">
                  <Nav.Link className={"js-scroll-trigger"} href="#sobre" onClick={Scroller.handleAnchorScroll}>Sobre financiae</Nav.Link>
                </li>
                <li className="nav-item">
                  <Nav.Link className={"js-scroll-trigger"} href="#comoFunciona" onClick={Scroller.handleAnchorScroll}>Cómo funciona financiae</Nav.Link>
                </li>

                <li className="nav-item">
                  <Nav.Link className={"js-scroll-trigger"} href="#contacto" onClick={Scroller.handleAnchorScroll}>Contacto</Nav.Link>
                </li>
                <li className="nav-item solo-movil">
                  <Nav.Link href="https://app.monitordecesiones.cl" >Ingresar app financiae</Nav.Link>
                </li>

                <li className="nav-item img-nav">
                  <a href="http://www.sii.cl/servicios_online/1039-emp_prov_fe-1184.html" alt="SII" className="">
                    <Image fluid={sii.childImageSharp.fluid} />
                  </a>
                </li>

                <li className="nav-item img-nav poweredby">
                  <a href="http://www.abstrahere.com" alt="Abstrahere" >
                    <Image fluid={poweredby.childImageSharp.fluid} />
                  </a>
                </li>
              </Scrollspy>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <div className="contenedor-form-login">
          <Container>
            <div className="ingresoinicio">
              <div className="inicioinputs">
                <input type="text" name="user" id="user" placeholder="11111111-1" title="Se nesecita un usuario" className="inloginicio" maxLength="11" required="" />
                <FaUserAlt className="fainginicio" />
              </div>
              <div className="inicioinputs">
                <input type="password" name="pass" id="pass" placeholder="Contraseña" title="Se nesecita una contraseña" className="inloginicio" maxLength="10" required="" />
                <FaUnlockAlt className="fainginicio" />
              </div>
              <button className="btn btn-block btn-ingresarinicio btn-flatr" >Ingresar</button>
            </div>
          </Container>
        </div>


      </Navbar>
    </>
  );

}

export default Header
